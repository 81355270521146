import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ContentBlock from "../../shared/sub/contentBlock"

import AboutCalculatorSection from "./aboutCalculatorStyle"

const AboutCalculator = () => {
  const {
    sanityCalculatorEquityPage: { aboutCalculator },
  } = useStaticQuery(graphql`
    query {
      sanityCalculatorEquityPage {
        aboutCalculator {
          _rawContent(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  `)

  return (
    <AboutCalculatorSection>
      <ContentBlock blocks={aboutCalculator._rawContent} />
    </AboutCalculatorSection>
  )
}

export default AboutCalculator
