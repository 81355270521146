import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import TextAndButtonBlock from "../../shared/textAndButtons"

const CalculatorCta = () => {
  const {
    sanityCalculatorEquityPage: { calculatorCta },
  } = useStaticQuery(graphql`
    query {
      sanityCalculatorEquityPage {
        calculatorCta {
          text
          button {
            linkText
            url
          }
        }
      }
    }
  `)

  return (
    <div className="container">
      <TextAndButtonBlock
        midLine={true}
        text={calculatorCta.text}
        button={calculatorCta.button}
      />
    </div>
  )
}

export default CalculatorCta
