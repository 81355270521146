import styled from "styled-components"

export default styled.div.attrs({ className: "results" })`
  padding: 4rem;
  background-color: var(--red);
  border-radius: 0.4rem;

  @media only screen and (max-width: 768px) {
    padding: 2rem;
  }

  h3 {
    color: white;
    font-family: medium;
    margin-bottom: 2rem;
  }

  ul {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;

      &:first-child {
        padding-bottom: 1rem;
        margin-bottom: 1.2rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);

        span.label {
          font-family: regular;
          color: white;
        }

        span.result {
          font-family: bold;
          color: white;
        }
      }

      span {
        &.label {
          color: var(--white);
          font-family: thin;
          font-size: 1.4rem;
        }

        &.result {
          color: var(--white);
          font-size: 1.6rem;
        }
      }
    }
  }
`
