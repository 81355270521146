import React from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import ContentBlock from "../../shared/sub/contentBlock"
import WhatAndHowSection from "./whatAndHowStyle"

const Disclaimer = () => {
  const {
    sanityCalculatorEquityPage: { disclaimerText },
  } = useStaticQuery(graphql`
    query {
      sanityCalculatorEquityPage {
        disclaimerText {
          _rawContent(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  `)

  if (disclaimerText == null) return null

  return (
    <WhatAndHowSection margin="0 auto 50px auto">
      <div className="disclaimer-content">
        <ContentBlock blocks={disclaimerText._rawContent} />
      </div>
    </WhatAndHowSection>
  )
}

export default Disclaimer
