import React, { useState } from "react";
import Inputs from "./inputs";
import Results from "./results";
import CalculatorSection from "./calculatorStyle";

const MortgageRepaymentCalculator = () => {
  const [inputs, setInputs] = useState({
    occupied_value: 0,
    mortgage_size: 0,
    other_investments: 0,
    other_mortgages: 0,
    should_show: false,
  });

  return (
    <>
      <CalculatorSection>
        <div className="container">
          <Inputs inputs={inputs} setInputs={setInputs} />
          <Results inputs={inputs} />
        </div>
      </CalculatorSection>
    </>
  );
};

export default MortgageRepaymentCalculator;
