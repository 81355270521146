import styled from "styled-components"

export default styled.div.attrs({ className: "inputs" })`
  background-color: #f1ece6;
  padding: 4rem 4rem 5rem 4rem;
  color: var(--red);
  border-radius: 0.4rem;

  @media only screen and (max-width: 768px) {
    padding: 2rem;
  }

  h3 {
    color: var(--red);
    font-family: medium;
  }

  label {
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .row {
    display: flex;
    align-items: center;

    .ant-slider {
      flex-grow: 1;
    }

    .ant-input-number {
      height: fit-content;
      margin-left: 2rem;
    }
  }
`
