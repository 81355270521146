import React from "react"
import MyLink from "../sub/myLink"

import TextAndButtonBlock from "./indexStyle"

interface Props {
  text: string
  button: {
    linkText: string
    url: string
  }
  midLine?: boolean
}

const TextAndButton: React.FC<Props> = ({ text, button, midLine = false }) => {
  return (
    <TextAndButtonBlock midLine={midLine}>
      {text && <p>{text}</p>}
      {button && (
        <MyLink className="theme-btn block white" url={button.url}>
          {button.linkText}
        </MyLink>
      )}
    </TextAndButtonBlock>
  )
}

export default TextAndButton
