import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import TextAndButtonBlock from "../../shared/textAndButtons"

const TextAndButton = () => {
  const {
    sanityCalculatorEquityPage: { textAndButton },
  } = useStaticQuery(graphql`
    query {
      sanityCalculatorEquityPage {
        textAndButton {
          text
          button {
            linkText
            url
          }
        }
      }
    }
  `)

  if (textAndButton == null) return null

  return (
    <div className="container">
      <TextAndButtonBlock
        midLine={true}
        text={textAndButton.text}
        button={textAndButton.button}
      />
    </div>
  )
}

export default TextAndButton
