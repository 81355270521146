import React, { useState } from "react";
import styled from "styled-components";

import ResultsBlock from "./resultsStyle";

interface Props {
  inputs: {
    mortgage_size: number;
    occupied_value: number;
    other_investments: number;
    other_mortgages: number;
    should_show: boolean;
  };
}

const HoverContainer = styled.span`
  display: inline-flex;
  flex-direction: row;
  background-color: var(--white);
  color: var(--red);
  padding: 1px 6px;
  font-size: 10px;
  margin-left: 10px;
  border-radius: 50%;

  position: relative;
  cursor: pointer;
`;

const InfoPopUp = styled.div`
  position: absolute;
  color: black;
  background-color: var(--white);
  top: 0px;
  width: 275px;
  right: -275px;
  z-index: 2;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 5px 6px 18px 2px rgba(0, 0, 0, 0.33);
  @media only screen and (max-width: 600px) {
    width: 150px;
    right: -150px;
  }
`;

const rate = 0.30;

const Results: React.FC<Props> = ({ inputs }) => {
  const [newBuildHoverOn, setNewBuildHoverOn] = useState(false);
  const [existingHoverOn, setExistingHoverOn] = useState(false);

  const decimalFix = (num: number) => (Math.round(num * 100) / 100).toFixed(1);

  const { occupied_value, mortgage_size, other_investments, other_mortgages, should_show } = inputs;

  const useableEquity =
    occupied_value -
    occupied_value * 0.2 -
    mortgage_size +
    (other_investments - other_investments * rate - other_mortgages);

  const existingBuild = useableEquity / rate;
  const newBuild = useableEquity / 0.2;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <ResultsBlock>
      {should_show ? (
        <>
          <h3>Results</h3>
          <ul>
            <li>
              <span className="label">Useable equity </span>
              <span className="result">
                {formatter.format(parseFloat(decimalFix(useableEquity)))}
              </span>
            </li>
            <li>
              <span className="label">
                Existing
                <HoverContainer
                  onMouseEnter={() => setExistingHoverOn(true)}
                  onMouseLeave={() => setExistingHoverOn(false)}
                >
                  ?
                  {existingHoverOn && (
                    <InfoPopUp>
                      <p>Maximum purchase price for an existing property</p>
                    </InfoPopUp>
                  )}
                </HoverContainer>
              </span>
              <span className="result">
                {formatter.format(parseFloat(decimalFix(existingBuild)))}
              </span>
            </li>
            <li>
              <span className="label">
                New Build
                <HoverContainer
                  onMouseEnter={() => setNewBuildHoverOn(true)}
                  onMouseLeave={() => setNewBuildHoverOn(false)}
                >
                  ?
                  {newBuildHoverOn && (
                    <InfoPopUp>
                      <p>Maximum purchase price for a new build property</p>
                    </InfoPopUp>
                  )}
                </HoverContainer>
              </span>
              <span className="result">{formatter.format(parseFloat(decimalFix(newBuild)))}</span>
            </li>
          </ul>
        </>
      ) : (
        <>
          <h3>Click calculate to display your results</h3>
        </>
      )}
    </ResultsBlock>
  );
};

export default Results;
