import firebase from "gatsby-plugin-firebase";
import { useEffect, useState } from "react";

export const useSetDoc = async (collection: string, data: object) => {
  const firestore = firebase.firestore();
  const collectionId = `${collection}${process.env.NODE_ENV === "production" ? "" : "-dev"}`;

  const docRef = firestore.collection(collectionId).doc();
  const dataToSave = {
    ...data,
    id: docRef.id,
    creationDate: new Date().toISOString(),
  };

  console.log("Save doc", dataToSave);

  return await docRef.set(dataToSave, { merge: true }).catch((error: any) => {
    if (error?.message) {
      console.log({ error });
    }
  });
};

export const useAnonomousLogin = async () => {
  const [user, setUser] = useState();

  useEffect(() => {
    const signin = async () => {
      return await firebase
        .auth()
        .signInAnonymously()
        .then((results: any) => {
          return results.user;
        })
        .catch((error: any) => {
          console.log({ error });
          return null;
        });
    };
    signin().then((user: any) => setUser(user));
  }, []);

  useEffect(() => {
    console.log({ user });
  }, [user]);

  return user;
};
