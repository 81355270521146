import styled from "styled-components"

export default styled.section.attrs({ className: "calculator-section" })`
  margin: 5rem 0;
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    max-width: 1000px;

    @media only screen and (max-width: 976px) {
      grid-template-columns: 1fr;
      width: 90%;
    }
  }

  .disclaimer-content {
    color: var(--red);
    text-align: left;
    margin: 50px 0px;
    font-size: 16px;
    width: 70%;
    margin: 50px auto;
    span {
      font-family: "bold";
      text-decoration: underline;
      cursor: pointer;
      opacity: 1;
      &:hover {
        opacity: 0.6;
      }
      transition: opacity 0.2s;
    }

    @media only screen and (max-width: 976px) {
      width: 90%;
    }
  }
`
