import React from "react"
import PageSeo from "../../components/calculators/equity/seo"
import CalculatorsTabs from "../../components/calculators/calculatorsTabs"
import UseableEquityCalculator from "../../components/calculators/equity/calculator"
import AboutCalculator from "../../components/calculators/equity/aboutCalculator"
import WhatAndHow from "../../components/calculators/equity/whatAndHow"
import CalculatorCta from "../../components/calculators/equity/calculatorCta"
import TextAndButton from "../../components/calculators/equity/textAndButton"

import BlogsSection from "../../components/shared/blogsSection"
import Disclaimer from "../../components/calculators/equity/disclaimer"
import { Header } from "@global"
import { Query } from "@graphql-types"
import { colors } from "@util/constants"
import { useStaticQuery, graphql } from "gatsby"

const HomeEquity = () => {
  const { sanityCalculatorEquityPage }: Query = useStaticQuery(graphql`
    query {
      sanityCalculatorEquityPage {
        title
        categories {
          colorList
        }
      }
    }
  `)

  if (sanityCalculatorEquityPage == null) return null
  const { title, categories } = sanityCalculatorEquityPage

  const categoryColor = categories && categories[0] && categories[0].colorList

  const headerData = {
    headerColor: categoryColor ?? colors.maroon,
  }

  return (
    <div className="subpage-container">
      <PageSeo />
      <Header title={title} headerData={headerData} />
      <CalculatorsTabs />
      <AboutCalculator />
      <UseableEquityCalculator />
      <Disclaimer />
      <CalculatorCta />
      <WhatAndHow />
      <TextAndButton />
      <BlogsSection title="Blogs" />
    </div>
  )
}

export default HomeEquity
