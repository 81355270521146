import styled from "styled-components"

export default styled.section<{ margin?: string }>`
  max-width: 1000px;
  width: 80%;
  margin: 5rem auto 7rem auto;
  ${({ margin }) => margin && `margin: ${margin};`};
  color: var(--red);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--red);
  }
  p {
    margin-bottom: 20px;
  }
  .disclaimer-content {
    color: var(--red);
    text-align: left;
    margin: 10px 0px;
    width: 100%;

    span {
      font-family: "bold";
      text-decoration: underline;
      cursor: pointer;
      opacity: 1;
      &:hover {
        opacity: 0.6;
      }
      transition: opacity 0.2s;
    }
  }
`
