import styled from "styled-components"

export default styled.div<{ midLine: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: 65% 25%;
  grid-gap: 10%;
  justify-items: center;
  align-items: center;
  background-color: var(--red);
  padding: 4rem 8rem;
  border-radius: 0.8rem;

  @media only screen and (max-width: 1000px) {
    padding: 3rem 4rem;
    grid-template-columns: 60% 30%;
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  p {
    display: block;
    color: var(--white);

    @media only screen and (max-width: 900px) {
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  a {
    margin-right: auto;

    @media only screen and (max-width: 900px) {
      margin-right: unset;
    }
  }

  ${props =>
    props.midLine &&
    `
    &:after {
    content: "";
    position: absolute;
    left: calc(70% - 3rem);
    top: 50%;
    display: block;
    height: 4rem;
    width: 2px;
    background-color: var(--white);
    opacity: 0.2;
    transform: translateY(-50%);

    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
  `}
`