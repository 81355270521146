import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ContentBlock from "../../shared/sub/contentBlock"
import WhatAndHowSection from "./whatAndHowStyle"

const WhatAndHow = () => {
  const {
    sanityCalculatorEquityPage: { howToApply },
  } = useStaticQuery(graphql`
    query {
      sanityCalculatorEquityPage {
        howToApply {
          _rawContent(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  `)

  // if (howToApply == null) return null

  return (
    <WhatAndHowSection>
      <ContentBlock blocks={howToApply._rawContent} />
    </WhatAndHowSection>
  )
}

export default WhatAndHow
