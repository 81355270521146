import { useAnonomousLogin, useSetDoc } from "@lib/firebase";
import { anyValueToNumber, formatValue, parseValue } from "@util/helpers";
import { useGetIP } from "@util/hooks";
import { Button, GiveMeSomeSpace } from "@util/standard";
import React from "react";
import InputsBlock from "./inputsStyle";
import { pushUseableEquityCalculatorData } from "@util/dataLayer";

interface Props {
  inputs: {
    mortgage_size: number;
    occupied_value: number;
    other_investments: number;
    other_mortgages: number;
    should_show: boolean;
  };
  setInputs: any;
}

const Inputs: React.FC<Props> = ({ inputs, setInputs }) => {
  const ip = useGetIP();
  useAnonomousLogin();

  const handleClick = () => {
    setInputs({
      ...inputs,
      should_show: true,
    });

    const LVR =
      (inputs.mortgage_size + inputs.other_mortgages) /
      (inputs.occupied_value + inputs.other_investments);

    const data = {
      ownerOccupiedValues: inputs.occupied_value,
      outstandingMortgages: inputs.mortgage_size,
      investmentMortgages: inputs.other_mortgages,
      investmentValues: inputs.other_investments,
      LVR,
    };

    pushUseableEquityCalculatorData(data);

    useSetDoc("useable-equity", {
      mortgageSize: anyValueToNumber(inputs.mortgage_size),
      occupiedValue: anyValueToNumber(inputs.occupied_value),
      otherInvestments: anyValueToNumber(inputs.other_investments),
      otherMortgages: anyValueToNumber(inputs.other_mortgages),
      userAgent: window.navigator.userAgent,
      ipAddress: ip.ip,
      country: ip.country,
    });
  };

  return (
    <InputsBlock>
      <label htmlFor="occupied_value">Owner Occupied Property Value</label>
      <input
        style={{ width: "100%" }}
        name="occupied_value"
        value={formatValue(inputs.occupied_value)}
        onChange={value => {
          setInputs({
            ...inputs,
            occupied_value: parseValue(value.target.value),
          });
        }}
      />
      <label htmlFor="mortgage_size">Current Outstanding Mortgage</label>
      <input
        step={10000}
        style={{ width: "100%" }}
        name="mortgage_size"
        value={formatValue(inputs.mortgage_size)}
        onChange={value => {
          setInputs({
            ...inputs,
            mortgage_size: parseValue(value.target.value),
          });
        }}
      />
      <label htmlFor="other_investment_size">Total Value of Existing Investment Properties</label>
      <input
        step={10000}
        style={{ width: "100%" }}
        name="other_investment_size"
        value={formatValue(inputs.other_investments)}
        onChange={value => {
          setInputs({
            ...inputs,
            other_investments: parseValue(value.target.value),
          });
        }}
      />
      <label htmlFor="other_mortgage_size">Outstanding Mortgage on Investment Properties</label>
      <input
        step={10000}
        style={{ width: "100%" }}
        name="other_mortgage_size"
        value={formatValue(inputs.other_mortgages)}
        onChange={value => {
          setInputs({
            ...inputs,
            other_mortgages: parseValue(value.target.value),
          });
        }}
      />
      <GiveMeSomeSpace space={10} />
      <Button onClick={handleClick}>Calculate useable equity</Button>
    </InputsBlock>
  );
};

export default Inputs;
