import { isBrowser } from "./helpers"

//Page view
export function sendPageView(url: string) {
  if (!isBrowser()) return null
  //@ts-ignore
  window.dataLayer = window.dataLayer || []
  //@ts-ignore
  return dataLayer.push({
    event: "page_view",
    page: {
      page_url: url,
      page_title: document.title,
    },
  })
}

interface MortgageCalculatorLayer {
  loanAmount: number
  interestRate: number
}

export function pushMortgageCalculatorData({
  loanAmount,
  interestRate,
}: MortgageCalculatorLayer) {
  if (!isBrowser()) return null
  //@ts-ignore
  window.dataLayer = window.dataLayer || []
  //@ts-ignore
  return dataLayer.push({
    event: "mortgage_calculator_submit",
    interest_rate: interestRate,
    loan_amount: loanAmount,
  })
}

interface BorrowingCalculatorLayer {
  income: number
  expenses: number
  otherHomeLoans: number
}
export function pushBorrowingCalculatorData({
  income,
  expenses,
  otherHomeLoans,
}: BorrowingCalculatorLayer) {
  if (!isBrowser()) return null
  //@ts-ignore
  window.dataLayer = window.dataLayer || []
  //@ts-ignore
  return dataLayer.push({
    event: "borrowing_calculator_submit",
    income: income,
    expenses: expenses,
    other_home_loans: otherHomeLoans,
  })
}

interface UseableEquityCalculatorLayer {
  ownerOccupiedValues: number
  outstandingMortgages: number
  investmentValues: number
  investmentMortgages: number
  LVR: number
}
export function pushUseableEquityCalculatorData({
  ownerOccupiedValues,
  outstandingMortgages,
  investmentMortgages,
  investmentValues,
  LVR,
}: UseableEquityCalculatorLayer) {
  if (!isBrowser()) return null
  //@ts-ignore
  window.dataLayer = window.dataLayer || []
  //@ts-ignore
  return dataLayer.push({
    event: "useable_equity_calculator_submit",
    owner_occupied_values: ownerOccupiedValues,
    outstanding_mortgages: outstandingMortgages,
    investment_mortgages: investmentMortgages,
    investmentValues: investmentValues,
    LVR: LVR,
  })
}
